<template>
  <div class="container">
    <div class="footer_container">
      <div class="logo_container">
        <img class="logo" :src="footerLogo">
      </div>
      <div class="social_container_footer">
        <!-- <span class="social_icon" >
          <i class="fab fa-telegram"></i>
        </span> -->
        <span class="social_icon" >
          <i class="fab fa-twitter" @click="redirect('twitter')" ></i>
        </span>
        <span class="social_icon" style="">
          <i class="fab fa-discord" @click="redirect('discord')"></i>
        </span>
        <span class="social_icon" >
          <i class="fab fa-instagram" @click="redirect('insta')"></i>
        </span>
      </div>
      <div class="reserved">
        © 2021 All rights reserved
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Footer',
  props: {},
  data () {
    return {
      footerLogo: require('@/assets/aliens_colony_logo_double_lines_white_alpha.png')
    }
  },
  methods: {
    redirect (social) {
      if (social === 'insta') {
        window.open('https://www.instagram.com/alienscolonynft/', '_blank').focus()
      } else if (social === 'twitter') {
        window.open('https://twitter.com/AliensColonyNFT', '_blank').focus()
      } else if (social === 'discord') {
        window.open('https://discord.gg/4qwt7AD95h', '_blank').focus()
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
.reserved {
  margin-bottom: 3%;
}
.footer_container {
  /* background: green; */
  display: flex;
  flex-direction: column;
  width: 60%;
  margin: 0 auto;
}
.social_container {
  width: 100%;
  margin: 0 auto;
}
.logo {
  width: 100%;
}
.logo_container {
  width: 30%;
  margin: 0 auto;
}
.container {
  display: flex;
  margin: 0 auto;
  width: 100%;
  flex-direction: column;
}
.social_container_footer {
  margin-top: 5%;
  margin-bottom: 5%;
}
.social_container_footer > span {
  margin: 15px;
}
/* ultra small */
@media only screen and (max-width: 375px) {

}
/* small */
@media only screen and (max-width : 768px) {
  .social_icon {
    font-size: 20px;
  }
  .logo_container {
    width: 40%;
  }
  .footer_container {
    width: 80%;
  }
}
@media only screen and (min-width: 375px) and (max-width: 960px) {

}
/* medium */
@media only screen and (min-width : 768px) and (max-width : 1024px) {

}
/* large */
@media (min-width: 1500px) {

}
</style>
