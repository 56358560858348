<template>
  <div class="container">
    <filter-menu @filter="filterData" :data="traits"/>
    <div class="card_container">
      <rarity-card
      v-for="(trait, i) in filteredList"
      class="card"
      :key="i"
      :imgSrc="trait.asset"
      :type="trait.type"
      :name="trait.name"
      :pourcentageDrop="trait.pourcentageDrop"
      :rarity="trait.rarity"
      />
    </div>
  </div>
</template>

<script>
import RarityCard from '@/components/RarityCard.vue'
import FilterMenu from '@/components/FilterMenu.vue'

export default {
  name: 'Rarity',
  components: {
    RarityCard,
    FilterMenu
  },
  data () {
    return {
      traits: [{
        type: 'background',
        name: 'Blue',
        rarity: 'Common',
        pourcentageDrop: 11.2,
        asset: '08.jpg'
      }, {
        type: 'Glasses',
        name: 'glasses 1',
        rarity: 'Uncommon',
        pourcentageDrop: 11.2,
        asset: '08.jpg'
      }, {
        type: 'Mouth',
        name: 'Mouth 1',
        rarity: 'Rare',
        pourcentageDrop: 11.2,
        asset: '08.jpg'
      }, {
        type: 'Head',
        name: 'Blue',
        rarity: 'Legendary',
        pourcentageDrop: 11.2,
        asset: '08.jpg'
      }, {
        type: 'Hand',
        name: 'Blue',
        rarity: 'Common',
        pourcentageDrop: 11.2,
        asset: '08.jpg'
      }],
      filter: 'all',
      filteredList: []
    }
  },
  mounted () {
    this.filteredList = this.traits
  },
  methods: {
    filterData (value) {
      const tmp = []
      if (value === 'All') {
        this.filteredList = this.traits
        return
      }
      for (const i in this.traits) {
        const trait = this.traits[i]
        if (trait.rarity === value || trait.type === value) {
          tmp.push(trait)
        }
      }
      this.filteredList = tmp
      console.log('eVENT trigger: ', value)
    }
  }
}
</script>

<style lang="scss" scoped>
.container {
  display: flex;
  max-width: 1300px;
  margin: 0 auto;
  flex-direction: column;
}
.card {
  flex-basis: auto;
  flex: 0 1 calc(75% - 1em);
  margin-bottom: 3%;
}
.card_container {
  flex-wrap: wrap;
  display: flex;
  width: 60%;
  margin: 0 auto;
  justify-content: space-between;
}
@media screen and (min-width: 40em) {
    .card_container {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        width: 80%;
   }

    .card {
        flex: 0 1 calc(50% - 1em);
    }
}

@media screen and (min-width: 60em) {
    .card_container {
   }

    .card {
        flex: 0 1 calc(25% - 1em);
    }
}

@media screen and (min-width: 52em) {
    .container {

    }
}
</style>
