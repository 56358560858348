<template>
  <div class="home">
    <mint/>
    <discord2/>
    <!-- <about/>
    <metaverse/>
    <carousel-image/>
    <rarity-section/>
    <invasion-road-map/>
    <development-road-map/>
    <team/>
    <faq-section/>
    <discord/> -->
  </div>
</template>

<script>
import Mint from '@/components/Mint.vue'
// import About from '@/components/About.vue'
// import InvasionRoadMap from '@/components/InvasionRoadMap.vue'
// import DevelopmentRoadMap from '@/components/DevelopmentRoadMap.vue'
// import Team from '@/components/Team.vue'
// import FaqSection from '@/components/FaqSection.vue'
// import Discord from '@/components/Discord.vue'
// import Metaverse from '@/components/Metaverse.vue'
// import CarouselImage from '@/components/CarouselImage.vue'
// import RaritySection from '@/components/RaritySection.vue'
import Discord2 from '@/components/Discord2.vue'

export default {
  name: 'Home',
  components: {
    Mint,
    // About,
    // InvasionRoadMap,
    // DevelopmentRoadMap,
    // Team,
    // FaqSection,
    // Discord,
    // Metaverse,
    // CarouselImage,
    // RaritySection,
    Discord2
  }
}
</script>
