<template>
  <div class="container">
    <div class="discord_section">
      <!-- <section-title :titleFirstPart="'GET'" :titleSecondPart="'WHITELISTED'" /> -->
      <!-- <h1></h1> -->
      <div class="button_section">
        <mint-button2 />
      </div>
    </div>
  </div>
</template>

<script>

// import SectionTitle from '@/components/SectionTitle.vue'
import mintButton2 from '@/components/MintButton2.vue'

export default {
  name: 'Discord',
  props: {},
  components: {
    // SectionTitle,
    mintButton2
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h1 {
  font-size: 1.6vw;
}
.discord_section {
  width: 80%;
  margin: 0 auto;
}
.container{
  width: 100%;
  margin: 0 auto;
}
.button_section {
  margin-top: 1%;
  margin-bottom: 4%;
}
/* ultra small */
@media only screen and (max-width: 375px) {

}
/* small */
@media only screen and (max-width : 768px) {
  h1 {
    font-size: 4vw;
  }
.button_section {
  margin-top: 1%;
  margin-bottom: 10%;
}
}
@media only screen and (min-width: 375px) and (max-width: 960px) {

}
/* medium */
@media only screen and (min-width : 768px) and (max-width : 1024px) {

}
/* large */
@media (min-width: 1500px) {

}
</style>
