<template>
  <div class="container" :style="{'background-image': 'linear-gradient(360deg, transparent 86%, #131222),url(' + require('@/assets/banner_header-2.jpg') + ')'}">
    <div class="content_wrapper">
      <div class="alienBanner">
        <img :src="alienBanner" class="alien_image">
      </div>
      <!-- <div class="mint__information_container">
        <div class="supply_section">
          <div class="icon_container">
            <img :src="supplyIcon" >
          </div>
          <div class="texts_container">
            <div class="section_title">
              SUPPLY
            </div>
            <div class="section_description">
              10 000 Unique digital collectibles aliens generated by code stored on the SOLANA blockchain.
            </div>
          </div>
        </div>
        <hr class="hr_separator"/>
        <div class="traits_section">
          <div class="icon_container">
            <img :src="traitsIcon">
          </div>
          <div class="texts_container">
            <div class="section_title">
              TRAITS
            </div>
            <div class="section_description">
              Each alien is made up with 8 attributes made from 200 hand drawn assets.
            </div>
          </div>
        </div>
        <hr class="hr_separator"/>
        <div class="minting_section">
            <div class="icon_container">
              <img :src="mintingIcon">
            </div>
            <div class="texts_container">
            <div class="section_title">
              MINTING
            </div>
            <div class="section_description">
              1.5 SOL per mint. <br/> The sell will take place on <span class="bold_text"> 15 December 2021</span>.
            </div>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
// import Discord2 from '@/components/Discord2.vue'

export default {
  name: 'Main',
  props: {},
  components: {
    // Discord2
  },
  data () {
    return {
      alienBanner: require('@/assets/aliens_banner.png'),
      mintingIcon: require('@/assets/icon_minting.png'),
      supplyIcon: require('@/assets/icon_supply.png'),
      traitsIcon: require('@/assets/icon_traits.png')
    }
  }
}
</script>

<style scoped>
.content_wrapper {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  width: 60%;
  max-width: 1300px;
  margin-top: 5%;
}

.section_description {
  font-weight: 400;
  font-size: 1vw;
  width: 80%;
  margin: 0 auto;
  line-height: 1.4;
}
.section_title {
  width: 65%;
  font-weight: 900;
  line-height: 2.3;
  font-size: 1.2vw;
  margin: 0 auto;
}
.supply_section, .traits_section, .minting_section {
  width: 33%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
.icon_container {
  display: flex;
  width: 30%;
  height: 30%;
  margin: 0 auto;
}
.icon_container > img {
  width: 100%;
  height: 100%;
  /* margin-top: 10px; */
  margin: 0 auto;
}
.mint__information_container {
  border-radius: 10px;
  background-color:#26253f;
  height: 100%;
  width: auto;
}
.supply_section, .traits_section, .minting_section {
  width: 33%;
  padding: 2%;
}
.alien_image {
  width: 100%;
}
.container {
  object-fit: fill;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  background-position: 0px 0px, 50% 0%;
  background-size: auto, cover;
  background-repeat: repeat, no-repeat;
  background-attachment: scroll, scroll;
  position: relative;
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
  align-content: stretch;
}
.alienBanner {
  display: flex;
  height: 100%;
  max-width: 100%;
  margin: auto auto 0;
}
.mint__information_container {
  display: flex;
  justify-content: space-between;
}
/* ultra small */
@media only screen and (max-width: 375px) {
  .content_wrapper {
      width: 90%;
  }
  .supply_section, .traits_section, .minting_section {
    width: 80%;
    margin: 0 auto;
    flex-direction: row;
  }
  .icon_container {
    width: 20%;
    height: 20%;
  }
  .icon_container > img {
    width: 100%;
    height: 100%;
  }
  .texts_container {
    width: 80%;
  }
  .hr_separator {
    width: 80%;
  }
}
/* small */
@media only screen and (max-width : 768px) {
  .section_title {
    width: 65%;
  }
  .content_wrapper {
      width: 70%;
  }
  .mint__information_container {
    flex-direction: column;
  }
  .supply_section, .traits_section, .minting_section {
    width: 80%;
    margin: 0 auto;
    flex-direction: row;
    padding: 1%;
  }
  .supply_section {
    margin-top: 2%;
  }
  .minting_section {
    margin-bottom: 2%;
  }
  .icon_container {
    width: 25%;
  }
  .icon_container > img {
    width: 100%;
    max-height: 70%;
  }
  .texts_container {
    width: 75%;
    text-align: left;
  }
  .hr_separator {
    width: 80%;
  }
  .section_description {
    font-size: 2vw;
  }
  .section_title {
    font-size: 2.5vw;
    width: 90%;
  }
  .alienBanner {
    max-width: 100%;
    height: 100%;
  }
  .section_description {
    width: 90%;
  }
}
@media only screen and (min-width: 375px) and (max-width: 960px) {

}
/* medium */
@media only screen and (min-width : 768px) and (max-width : 1024px) {

}
/* large */
@media (min-width: 1500px) {
  .content_wrapper {
      width: 60%;
  }
}
</style>
