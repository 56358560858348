<template>
  <div id="app">
    <div class="nav">
      <div class="nav_logo__container">
        <img @click="$router.push('/')" :src="navLogo" class="nav_logo">
      </div>
      <div class="social_container">
        <!-- <span class="social_icon" >
          <i class="fab fa-telegram"></i>
        </span> -->
        <span class="social_icon" >
          <i class="fab fa-twitter" @click="redirect('twitter')"></i>
        </span>
        <span class="social_icon" style="">
          <i class="fab fa-discord" @click="redirect('discord')"></i>
        </span>
        <span class="social_icon" >
          <i class="fab fa-instagram" @click="redirect('insta')"></i>
        </span>
      </div>
    </div>
    <router-view />
    <footer-component />
  </div>
</template>

<script>
import FooterComponent from '@/components/FooterComponent.vue'

export default {
  name: 'Home',
  components: {
    FooterComponent
  },
  data () {
    return {
      navLogo: require('@/assets/aliens_colony_logo_line_white_alpha.png')
    }
  },
  methods: {
    redirect (social) {
      if (social === 'insta') {
        window.open('https://www.instagram.com/alienscolonynft/', '_blank').focus()
      } else if (social === 'twitter') {
        window.open('https://twitter.com/AliensColonyNFT', '_blank').focus()
      } else if (social === 'discord') {
        window.open('https://discord.gg/4qwt7AD95h', '_blank').focus()
      }
    }
  }
}
</script>

<style>
@import url('https://fonts.googleapis.com/css?family=Poppins');
@import './assets/css/style.css';

img {
  -webkit-user-drag: none;
  -khtml-user-drag: none;
  -moz-user-drag: none;
  -o-user-drag: none;
}
html, body {
  display: block;
  font-family: 'Poppins', sans-serif;
}
body {
  margin: 0;
  background-color: #131222;
  color: white;
}
.nav_logo__container {
  width: 20%;
}
.social_container > span {
  margin-left: 25px;
  float: right;
  height: 100%;
}

i {
  position:relative;
  top: calc(50% - 10px); /* 50% - 3/4 of icon height */
}
#app {
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
}
.nav {
  width: 60%;
  max-width: 1300px;
  height: 10vh;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
}
.social_icon {
  color:white;
  font-size: 25px;
  cursor: pointer;
}
.social_container {
  float: right;
  width: 80%;
}
@media only screen and (max-width : 768px) {
  .nav {
    width: 90% !important;
  }
}
@media only screen and (min-width : 768px) and (max-width : 1024px) {
  .nav {
    width: 70% !important;
  }
}
.nav_logo {
  cursor: pointer;
  float: left;
  max-width: 250px;
  height: 100%;
  margin: 0 auto;
  object-fit: contain;
}
@media only screen and (max-width: 375px) {
    .nav_logo {
      max-width: 130px;
    }
    .social_icon {
      font-size: 12px;
    }
    .social_container > span {
      margin-left: 15px;
    }
}
/* end nav */
/* (720x1280) Galaxy Nexus, WXGA */
@media only screen and (min-width: 375px) and (max-width: 960px) {
    .nav_logo {
      max-width: 200px;
    }
    .social_icon {
      font-size: 15px;
    }
}
@media screen and (min-width: 1221px) {
  /* div {
     font-size: 20px !important;
  } */
}
@media (min-width: 1500px) {
  .nav_logo {
    max-width: 350px
  }
  .social_icon {
    font-size: 35px;
  }
}
</style>
