<template>
  <div class="container">
    <div class="card">
      <div class="card-header">
        <img :src="require(`@/assets/${imgSrc}`)" alt="rover" />
      </div>
      <div class="card-body">
      <span :class="`tag tag-${rarity}`">{{rarity}}</span>
      <h4>
        <span class="pre">
          Name
        </span>
        {{name}}
      </h4>
      <p>
        <span class="pre">
          Type
        </span>
        :{{type}}
      </p>
      <p>
        <span class="pre">
          Rarity
        </span>: {{pourcentageDrop}}%
      </p>
      <!-- <div class="user">
        <img src="https://yt3.ggpht.com/a/AGF-l7-0J1G0Ue0mcZMw-99kMeVuBmRxiPjyvIYONg=s900-c-k-c0xffffffff-no-rj-mo" alt="user" />
        <div class="user-info">
          <h5>July Dec</h5>
          <small>2h ago</small>
        </div>
      </div> -->
    </div>
  </div>
  </div>
</template>

<script>
export default {
  name: 'RarityCard',
  props: {
    type: String,
    imgSrc: String,
    name: String,
    rarity: String,
    pourcentageDrop: Number
  },
  methods: {
    getImage (imgSrc) {
      return imgSrc
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
@import url("https://fonts.googleapis.com/css2?family=Roboto&display=swap");
* {
  box-sizing: border-box;
}
.container {
  /* display: flex; */
  width: 25%;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.card {
  text-align: left;
  background-color: rgba(38, 38, 62, 1);
  color: white;
  border-radius: 10px;
  box-shadow: 0 2px 20px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  width: 100%;
}
.card-header img {
  width: 100%;
  object-fit: cover;
}
.card-body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 10px;
  /* min-height: 250px; */
}

.tag {
  background: #cccccc;
  border-radius: 50px;
  font-size: 12px;
  margin: 0;
  color: #fff;
  padding: 2px 10px;
  text-transform: uppercase;
  cursor: pointer;
}
.tag-Uncommon {
  background-color: #5e76bf;
}
.tag-Rare {
  background-color: #47bcd4;
}
.tag-Common {
  background-color: #5e76bf;
}
.tag-Legendary {
  background-color: gold;
}

.card-body p {
  font-size: 13px;
  /* margin: 0 0 40px; */
}
.user {
  display: flex;
  margin-top: auto;
}

.user img {
  border-radius: 50%;
  /* width: 40px;
  height: 40px; */
  margin-right: 10px;
}
.user-info h5 {
  margin: 0;
}
.user-info small {
  color: #545d7a;
}

</style>
